<template>
  <main
    class="supplier-payment-confirmation supplier-payment-success-police page_bg_grey"
  >
    <section class="payment-caption-section">
      <div class="payment-caption-container">
        <div class="payment-caption-wrapper payment-product-name">
          <span>{{ $t("SUPPLIER_PAYMENT.PAYMENT_POLICE_REPORTS") }}</span>
        </div>

        <div class="payment-caption-wrapper text-center">
          <img
            class="supplier-logo"
            src="/assets/img/logo/police.png"
          />
        </div>

        <div class="payment-caption-wrapper d-flex justify-content-center pt10">
        </div>
      </div>
    </section>

    <section class="payment-confirmation-data-section">
      <div class="payment-confirmation-data-top-block">
        <span class="payment-confirmation-data-top-block-text color-green">{{
          $t("SUPPLIER_PAYMENT.RAVKAV_CONFIRM_TXT")
        }}</span>
      </div>

      <div class="payment-confirmation-data">
        <div class="payment-confirmation-data-block">
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-title">
              {{ $t("CARMEL_TUNNELS.DETAILS_PLACE_TRANSACTION") }}
            </div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("REGISTER_RESELLER.BUSINESS_NAME") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">רמי לוי</div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("COMMON.DATE") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">
              2018/01/25
            </div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("COMMON.HOUR") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">08:23:15</div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("CARMEL_TUNNELS.TRANSACTION_APPROVAL") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">654542</div>
          </div>
        </div>

        <div class="payment-confirmation-data-block">
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-title">
              {{ $t("CARMEL_TUNNELS.INVOICE_DETAILS") }}
            </div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("SUPPLIER_PAYMENT.PAYER_CUSTOMER_NUMBER") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">
              045652456
            </div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("SUPPLIER_PAYMENT.REPORT_NUMBER") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">
              1.5435434; 2.53454345
            </div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("SUPPLIER_PAYMENT.AMOUNT_OF_INVOICES") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">
              2,000₪
            </div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("CARMEL_TUNNELS.SERVICE_FEE") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">
              15₪
            </div>
          </div>

          <div class="payment-confirmation-data-block-row red-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("CARMEL_TUNNELS.PAYMENT_SUM") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data weight-700">
              2015₪
            </div>
          </div>
          <div class="payment-confirmation-data-block-row confirm-action">
            <div class="payment-confirmation-data-block-row-label print-part">
              {{ $t("REPORT_SALES.FOR_PRINTING") }}
              <img src="/assets/img/print-light.svg" alt="">
            </div>
            <div class="payment-confirmation-data-block-row-data">
              <button class="primary-btn finish-btn">
                {{ $t("SUPPLIER_PAYMENT.FINISHED_AND_CLOSED") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="payment-confirmation-data-bottom-block">
        <div class="payment-confirmation-data-bottom-block-text">
          <span class="">{{ $t("CARMEL_TUNNELS.RESULT_TXT") }}</span>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "SupplierPaymentConfirmation212"
};
</script>

<style lang="scss" scoped>
.supplier-payment-success-police {
  .payment-caption-container {
    align-items: center;
    .payment-caption-wrapper {
      margin-top: 0;
    }
  }
  .supplier-logo {
    max-width: 120px;
    min-width: unset;
  }
  .payment-product-name,
  .payment-confirmation-data-top-block-text,
  .payment-confirmation-data-bottom-block-text {
    font-size: 30px;
  }
  .payment-confirmation-data-block-row,
  .payment-success-data-block-2-columns .payment-success-data-block-row {
    font-size: 20px;
  }
}
.ltr-type {
  .payment-form {
    text-align: left;
  }
  .input-tooltip-container {
    left: unset;
    right: 0;
  }
  .payment-form-with-tooltips .payment_field_wrapper .input {
    margin-left: 0;
    margin-right: 12px;
  }
}
@media screen and (max-width: 600px) {
  .payment-confirmation-data-block-row-label,
  .payment-confirmation-data-block-row-data,
  .payment-success-data-block-2-columns .payment-success-data-block-row-label,
  .payment-success-data-block-2-columns .payment-success-data-block-row-data {
    display: block;
    width: 100%;
  }
}

.confirm-action {
  align-items: center;
  display: flex;
  border-top: 1px solid #b2bbc2;
  border-bottom: 1px solid #b2bbc2;
  padding: 20px 0;
  margin-top: 30px;
  .print-part {
    align-items: center;
    display: flex;
    img {
      width: 50px;
      margin: 0 10px;
    }

  }
}
</style>
