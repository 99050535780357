<template>
    <main class="supplier-payment-success supplier-payment-success-185 page_bg_grey">

        <section class="payment-caption-section">

            <div class="payment-caption-container">

                <div class="payment-caption-wrapper payment-product-name">
                    <span v-if="supplierData && supplierData.name">{{ supplierData.name }}</span>
                </div>

                <div class="payment-caption-wrapper text-center">
                    <img class="supplier-logo" v-if="supplierData && supplierData.image" :src="supplierData.image" />
                </div>

                <div class="payment-caption-wrapper">
                    &nbsp;
                </div>

            </div>

        </section>

        <section class="payment-success-data-section">

            <div class="payment-success-data-top-block">
                <span class="payment-success-data-top-block-text">תשלום חשבון חשמל בוצע בהצלחה</span>
            </div>

            <div class="payment-success-data">

                <div class="payment-success-data-block payment-success-data-block-2-columns">

                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-title">פרטי מקום העסקה</div>
                    </div>

                    <div class="payment-success-data-block-row">
                        <div class="payment-success-data-block-row-label">שם העסק:</div>
                        <div class="payment-success-data-block-row-data">{{ businessName }}</div>
                    </div>

                    <div class="payment-success-data-block-row">
                        <div class="payment-success-data-block-row-label">תאריך:</div>
                        <div class="payment-success-data-block-row-data">{{ transactionDateString }}</div>
                    </div>

                    <div class="payment-success-data-block-row">
                        <div class="payment-success-data-block-row-label">שעה:</div>
                        <div class="payment-success-data-block-row-data">{{ transactionTimeString }}</div>
                    </div>

                    <div class="payment-success-data-block-row">
                        <div class="payment-success-data-block-row-label">מספר אישור עסקה:</div>
                        <div class="payment-success-data-block-row-data">{{ invoice }}</div>
                    </div>

                </div>

                <div class="payment-success-data-block payment-success-data-block-2-columns">

                    <div class="payment-success-data-block-row">
                        <div class="payment-success-data-block-title">פרטי לקוח</div>
                    </div>

                    <div class="payment-success-data-block-row">
                        <div class="payment-success-data-block-row-label">שם הלקוח:</div>
                        <div class="payment-success-data-block-row-data">{{ name }}</div>
                    </div>

                    <div class="payment-success-data-block-row">
                        <div class="payment-success-data-block-row-label">כתובת:</div>
                        <div class="payment-success-data-block-row-data">{{ address }}</div>
                    </div>

                    <div class="payment-success-data-block-row">
                        <div class="payment-success-data-block-row-label">מספר חוזה:</div>
                        <div class="payment-success-data-block-row-data">{{ contractNumber }}</div>
                    </div>

                    <div class="payment-success-data-block-row">
                        <div class="payment-success-data-block-row-label">קיימים חובות נוספים:</div>
                        <div class="payment-success-data-block-row-data">לא</div>
                    </div>

                    <div class="payment-success-data-block-row">
                        <div class="payment-success-data-block-row-label">סכום חשבונית:</div>
                        <div class="payment-success-data-block-row-data">{{ price }} ש"ח</div>
                    </div>

                    <div class="payment-success-data-block-row">
                        <div class="payment-success-data-block-row-label">סכום לתשלום:</div>
                        <div class="payment-success-data-block-row-data">{{ price }} ש"ח<span class="price-description red-text">עמלה בגין תשלום זה במערכת הינו 0 שח **</span></div>
                    </div>

                </div>

            </div>

            <div class="payment-success-data-bottom-block">

                <div class="payment-success-data-bottom-block-buttons">

                    <div class="separator-line"></div>

                    <div class="payment-success-data-bottom-block-button-wrapper">
                        <a class="link payment-button print-button" @click="handlePrintReceiptClick">
                            <span class="text">להדפסה</span>
                            <span class="printer-icon"></span>
                        </a>
                    </div>
                    <div class="payment-success-data-bottom-block-button-wrapper">
                        <a class="link payment-button close-button" @click="handleFinishAndCloseClick">סיים וסגור</a>
                    </div>

                    <div class="separator-line"></div>

                </div>

                <div class="payment-success-data-bottom-block-text"><span class="">בסיום התשלום צריך לספק ללקוח קבלה בלבד!</span></div>

            </div>

        </section>

    </main>
</template>

<script>
    import {mapState, mapActions} from 'vuex'
    import appConfig from '../../../appConfig'

    export default {
        name: 'SupplierPaymentSuccess185',
        props: {
            msg: String
        },
        mounted() {
        },
        computed: {
            ...mapState({
                current_transaction_id: state => state.supplier_payment.current_transaction_id,
                transactions_receipts: state => state.supplier_payment.transactions_receipts,
                current_payment_data: state => state.supplier_payment.current_payment_data,
                supplier_items: state => state.supplier.supplier_items,
                suppliers: state => state.supplier.suppliers,
                user_details: state => state.account.user_details && state.account.user_details.hasOwnProperty('details') ? state.account.user_details.details : null
            }),
            supplierId() {
                return this.$route.params.supplier_id
            },
            supplierData() {
                let supplierDataFromSuppliersItemsData = null;
                if (this.supplier_items) {
                    for (let supplier_type in this.supplier_items) {
                        for (let suppliers_type_item of this.supplier_items[supplier_type]) {
                            if (suppliers_type_item.id == this.supplierId) {
                                supplierDataFromSuppliersItemsData = {...suppliers_type_item};
                                break;
                            }
                        }
                    }
                }

                let supplierDataFromSuppliersData = null;
                if (this.suppliers && this.supplierId in this.suppliers) {
                    supplierDataFromSuppliersData = {...this.suppliers[this.supplierId]}
                }

                let supplierData = supplierDataFromSuppliersData ? supplierDataFromSuppliersData : supplierDataFromSuppliersItemsData
                return supplierData ? supplierData : null
            },
            paymentData() {
                let paymentData = this.current_payment_data && (this.supplierId in this.current_payment_data) ? this.current_payment_data[this.supplierId] : null

                return paymentData
            },
            contractNumber() {
                return this.paymentData && this.paymentData.hasOwnProperty('contractNumber') ? this.paymentData.contractNumber : ''
            },
            price() {
                return this.paymentData && this.paymentData.hasOwnProperty('price') ? this.paymentData.price : ''
            },
            itemId() {
                return this.paymentData && this.paymentData.hasOwnProperty('itemId') ? this.paymentData.itemId : ''
            },
            phoneNumber() {
                return this.paymentData && this.paymentData.hasOwnProperty('phoneNumber') ? this.paymentData.phoneNumber : ''
            },
            address() {
                return this.paymentData && this.paymentData.hasOwnProperty('address') ? this.paymentData.address : ''
            },
            invoice() {
                return this.paymentData && this.paymentData.hasOwnProperty('invoice') ? this.paymentData.invoice : ''
            },
            name() {
                return this.paymentData && this.paymentData.hasOwnProperty('name') ? this.paymentData.name : ''
            },
            businessName() {
                return this.user_details && this.user_details.businessName ? this.user_details.businessName : ''
            },
            transactionId() {
                return this.current_transaction_id && this.supplierId in this.current_transaction_id ? this.current_transaction_id[this.supplierId] : null
            },
            transactionReceiptData() {
                let transactionReceiptData = null;
                if (this.transactions_receipts && this.transactionId in this.transactions_receipts) {
                    transactionReceiptData = {...this.transactions_receipts[this.transactionId]}
                }

                return transactionReceiptData ? transactionReceiptData : null
            },
            transactionDateTimeString() {
                let transactionDateTimeString = null

                if (this.transactionReceiptData && this.transactionReceiptData.hasOwnProperty('transDateTime')
                    && this.transactionReceiptData.transDateTime) {
                    transactionDateTimeString = this.transactionReceiptData.transDateTime
                }

                return transactionDateTimeString
            },
            transactionDateTime() {
                let transactionDateTime = null

                if (this.transactionDateTimeString) {
                    transactionDateTime = new Date(Date.parse(this.transactionDateTimeString))
                }

                return transactionDateTime
            },
            transactionDateString() {
                let transactionDateString = ''

                if (this.transactionDateTime) {
                    transactionDateString = this.transactionDateTime.getDate().toString().padStart(2, '0') + '/' + (this.transactionDateTime.getMonth() + 1).toString().padStart(2, '0') + '/' + this.transactionDateTime.getFullYear()
                }

                return transactionDateString
            },
            transactionTimeString() {
                let transactionTimeString = ''

                if (this.transactionDateTime) {
                    transactionTimeString = this.transactionDateTime.getHours().toString().padStart(2, '0') + ":" + this.transactionDateTime.getMinutes().toString().padStart(2, '0') + ":" + this.transactionDateTime.getSeconds().toString().padStart(2, '0')
                }

                return transactionTimeString
            }
        },
        created() {
            this.getSupplier({'supplier_id': this.supplierId})
        },
        methods: {
            ...mapActions('supplier', {
                getSupplier: 'getSupplier'
            }),
            getApiURL() {
                return appConfig.apiUrl
            },
            handlePrintReceiptClick() {
                alert('receipt should be generated and printed.. still developing..')
            },
            handleFinishAndCloseClick() {
                this.$router.push({name: 'Payment'})
            }
        },
        updated: function () {
        },
    }
</script>

<style>
</style>