<template>
  <main
    class="supplier-payment-confirmation supplier-payment-confirmation-1013 page_bg_grey"
  >
    <section class="payment-caption-section hide__print">
      <div class="payment-caption-container">
        <div class="payment-caption-wrapper payment-product-name">
          <span>{{ $t("SUPPLIER_PAYMENT.PURCHASE_POWER_CODE_TXT") }}</span>
        </div>

        <div class="payment-caption-wrapper text-center">
          <img
            class="supplier-logo"
            src="/assets/img/logo/n-logo.png"
          />
        </div>

        <div class="payment-caption-wrapper d-flex justify-content-center pt10">
        </div>
      </div>
    </section>

    <section class="payment-confirmation-data-section">
      <div class="payment-confirmation-data-top-block hide__print">
        <span class="payment-confirmation-data-top-block-text color-green">{{
          $t("SUPPLIER_PAYMENT.RAVKAV_CONFIRM_TXT")
        }}</span>
      </div>

      <div class="payment-confirmation-data">
        <div class="payment-confirmation-data-block">
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-title">
              {{ $t("CARMEL_TUNNELS.DETAILS_PLACE_TRANSACTION") }}
            </div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("REGISTER_RESELLER.BUSINESS_NAME") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ businessName }}</div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("SUPPLIER_PAYMENT.BUSINESS_ADDRESS") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ businessAddress }}</div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("COMMON.DATE") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ transactionDateString }}</div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("COMMON.HOUR") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ transactionTimeString }}</div>
          </div>

        </div>

        <div class="payment-confirmation-data-block">
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-title">
              {{ $t("CARMEL_TUNNELS.INVOICE_DETAILS") }} {{ transactionId }}
            </div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("REPORT_SALES.CONTRACT_NUMBER") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ contractNumber }}</div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("SUPPLIER_PAYMENT.METER_NUMBER") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ meter_number }}</div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("SUPPLIER_PAYMENT.APPROVAL_NUMBER") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ approval_number }}</div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("SUPPLIER_PAYMENT.DATE_OF_PAYMENT") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ transDateTime || transactionDateString }}</div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("COMMON.ADDRESS") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ address }}</div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">{{ $t("SUPPLIER_PAYMENT.PURCHASE_AMOUNT") }}:</div>
            <div class="payment-confirmation-data-block-row-data">{{ purchased_amount }} ₪</div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">{{ $t("SUPPLIER_PAYMENT.PAYMENT_DEBTS_TXT") }}:</div>
            <div class="payment-confirmation-data-block-row-data">{{ reduced_amount }} ₪</div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">{{ $t("SUPPLIER_PAYMENT.TOTAL_NET_EXCLUDING_VAT") }}:</div>
            <div class="payment-confirmation-data-block-row-data">{{ net_amount }} ₪</div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">{{ $t("SUPPLIER_PAYMENT.COMMISSION") }}:</div>
            <div class="payment-confirmation-data-block-row-data">{{ finalCommission }} ₪</div>
          </div>
          <div class="payment-confirmation-data-block-row total-price">
            <div class="payment-confirmation-data-block-row-label">{{ $t("SUPPLIER_PAYMENT.TOTAL_PRICE") }}:</div>
            <div class="payment-confirmation-data-block-row-data">{{ price }} ₪</div>
          </div>

          <div class="loading-code result-payment-code">
            <span>{{ $t("SUPPLIER_PAYMENT.LOADING_CODE") }}:</span> <span class="payment-code">{{ KOD }}</span>
          </div>
          <div class="payment-confirmation-data-block-row confirm-action hide__print">
            <div class="payment-confirmation-data-block-row-label print-part cursor-pointer" @click="handlePrintReceiptClick">
              {{ $t("REPORT_SALES.FOR_PRINTING") }}
              <img src="/assets/img/print-light.svg" alt="">
            </div>
            <div class="payment-confirmation-data-block-row-data">
              <button class="primary-btn finish-btn" @click="handleFinishAndCloseClick">
                {{ $t("SUPPLIER_PAYMENT.FINISHED_AND_CLOSED") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="payment-confirmation-data-bottom-block hide__print">
        <div class="payment-confirmation-data-bottom-block-text">
          <span class="">{{ $t("CARMEL_TUNNELS.RESULT_TXT") }}</span>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import appConfig from "../../../appConfig";

export default {
  name: "SupplierPaymentConfirmation1013",
  props: {
    msg: String,
  },
  data() {
    return {
      currentDate: new Date(),
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      current_payment_data: (state) =>
        state.supplier_payment.current_payment_data,
      supplier_items: (state) => state.supplier.supplier_items,
      suppliers: (state) => state.supplier.suppliers,
      user_details: (state) =>
        state.account.user_details &&
        state.account.user_details.hasOwnProperty("details")
          ? state.account.user_details.details
          : null,
      transactions_receipts: state => state.supplier_payment.transactions_receipts
    }),
    supplierId() {
      return this.$route.params.supplier_id;
    },
    supplierData() {
      let supplierDataFromSuppliersItemsData = null;
      if (this.supplier_items) {
        for (let supplier_type in this.supplier_items) {
          for (let suppliers_type_item of this.supplier_items[supplier_type]) {
            if (suppliers_type_item.id == this.supplierId) {
              supplierDataFromSuppliersItemsData = { ...suppliers_type_item };
              break;
            }
          }
        }
      }

      let supplierDataFromSuppliersData = null;
      if (this.suppliers && this.supplierId in this.suppliers) {
        supplierDataFromSuppliersData = { ...this.suppliers[this.supplierId] };
      }

      let supplierData = supplierDataFromSuppliersData
        ? supplierDataFromSuppliersData
        : supplierDataFromSuppliersItemsData;
      return supplierData ? supplierData : null;
    },
    supplierName() {
        let supplierName = '';
        if (this.supplierData && this.supplierData.name) {
            supplierName = this.supplierData.name;
        }

        return supplierName;
    },
    paymentData() {
      let paymentData =
        this.current_payment_data &&
        this.supplierId in this.current_payment_data
          ? this.current_payment_data[this.supplierId]
          : null;

      return paymentData;
    },
    transactionId() {
        let transactionId = this.$route.params.transaction_id || null;
        if (transactionId == null && this.current_transaction_id && this.supplierId in this.current_transaction_id) {
            transactionId = this.current_transaction_id[this.supplierId];
        }

        return transactionId;
    },
    transactionReceiptData() {
        let transactionReceiptData = null;
        if (this.transactions_receipts && this.transactionId in this.transactions_receipts) {
            transactionReceiptData = {...this.transactions_receipts[this.transactionId]}
        }

        return transactionReceiptData ? transactionReceiptData : null
    },
    transactionReceiptDataDetails() {
        let transactionReceiptDataDetails = this.transactionReceiptData && 'details' in this.transactionReceiptData
            ? this.transactionReceiptData['details'] : null;

        return transactionReceiptDataDetails;
    },
    contractNumber() {
      let contractNumber = this.transactionReceiptDataDetails && 'contract_number' in this.transactionReceiptDataDetails
          ? this.transactionReceiptDataDetails['contract_number'] : null;
      if (contractNumber == null && this.paymentData && this.paymentData.hasOwnProperty('contractNumber')) {
          contractNumber = this.paymentData['contractNumber'];
      }

      return contractNumber;
    },
    finalCommission() {
      let finalCommission = 0;

      if (this.price && this.purchased_amount) {
          finalCommission = parseFloat(this.price) - parseFloat(this.purchased_amount);
      }

      return finalCommission;
    },
    price() {
        let price = this.transactionReceiptData && 'price' in this.transactionReceiptData
            ? this.transactionReceiptData['price'] : null;
        if (price == null && this.paymentData && this.paymentData.hasOwnProperty('price')) {
            price = this.paymentData['price'];
        }

        return price;
    },
    address() {
        let address = this.transactionReceiptDataDetails && 'address' in this.transactionReceiptDataDetails
            ? this.transactionReceiptDataDetails['address'] : null;
        if (address == null && this.paymentData && this.paymentData.hasOwnProperty('address')) {
            address = this.paymentData['address'];
        }
        return address;
    },
    purchased_amount() {
        let purchased_amount = this.transactionReceiptDataDetails && 'purchased_amount' in this.transactionReceiptDataDetails
            ? this.transactionReceiptDataDetails['purchased_amount'] : null;
        if (purchased_amount == null && this.paymentData && this.paymentData.hasOwnProperty('purchased_amount')) {
            purchased_amount = this.paymentData['purchased_amount'];
        }
        return purchased_amount;
    },
    net_amount() {
        let net_amount = this.transactionReceiptDataDetails && 'net_amount' in this.transactionReceiptDataDetails
            ? this.transactionReceiptDataDetails['net_amount'] : null;
        if (net_amount == null && this.paymentData && this.paymentData.hasOwnProperty('net_amount')) {
            net_amount = this.paymentData['net_amount'];
        }
        return net_amount;
    },
    reduced_amount() {
        let reduced_amount = this.transactionReceiptDataDetails && 'reduced_amount' in this.transactionReceiptDataDetails
            ? this.transactionReceiptDataDetails['reduced_amount'] : null;
        if (reduced_amount == null && this.paymentData && this.paymentData.hasOwnProperty('reduced_amount')) {
            reduced_amount = this.paymentData['reduced_amount'];
        }
        return reduced_amount;
    },
    meter_number() {
        let meter_number = this.transactionReceiptDataDetails && 'meter_number' in this.transactionReceiptDataDetails
            ? this.transactionReceiptDataDetails['meter_number'] : null;
        if (meter_number == null && this.paymentData && this.paymentData.hasOwnProperty('meter_number')) {
            meter_number = this.paymentData['meter_number'];
        }
        return meter_number;
    },
    approval_number() {
        let approval_number = this.transactionReceiptDataDetails && 'approval_number' in this.transactionReceiptDataDetails
            ? this.transactionReceiptDataDetails['approval_number'] : null;
        if (approval_number == null && this.paymentData && this.paymentData.hasOwnProperty('approval_number')) {
            approval_number = this.paymentData['approval_number'];
        }
        return approval_number;
    },
      transDateTime() {
        let transDateTime = this.transactionReceiptDataDetails && 'transDateTime' in this.transactionReceiptDataDetails
            ? this.transactionReceiptDataDetails['transDateTime'] : null;
        if (transDateTime == null && this.paymentData && this.paymentData.hasOwnProperty('transDateTime')) {
            transDateTime = this.paymentData['transDateTime'];
        }
        return transDateTime;
    },
    KOD() {
        let KOD = this.transactionReceiptDataDetails && 'KOD' in this.transactionReceiptDataDetails
            ? this.transactionReceiptDataDetails['KOD'] : null;
        if (KOD == null && this.paymentData && this.paymentData.hasOwnProperty('KOD')) {
            KOD = this.paymentData['KOD'];
        }
        return KOD;
    },
    phoneNumber() {
        let phoneNumber = this.transactionReceiptData && 'targetPhone' in this.transactionReceiptData
            ? this.transactionReceiptData['targetPhone'] : null;
        if (phoneNumber == null && this.paymentData && this.paymentData.hasOwnProperty('phoneNumber')) {
            phoneNumber = this.paymentData['phoneNumber'];
        }
        return phoneNumber;
    },
    businessName() {
        return this.transactionReceiptData && 'business_name' in this.transactionReceiptData
            ? this.transactionReceiptData['business_name'] : null;
    },
    businessAddress() {
        return this.transactionReceiptData && 'user_address' in this.transactionReceiptData
            ? this.transactionReceiptData['user_address'] : null;
    },
    transactionDateTimeString() {
      let transactionDateTimeString = null

      if (this.transactionReceiptData && this.transactionReceiptData.hasOwnProperty('transDateTime')
          && this.transactionReceiptData.transDateTime) {
        transactionDateTimeString = this.transactionReceiptData.transDateTime
      }

      return transactionDateTimeString
    },
    transactionDateTime() {
      let transactionDateTime = null

      if (this.transactionDateTimeString) {
        transactionDateTime = new Date(Date.parse(this.transactionDateTimeString))
      }

      return transactionDateTime
    },
    transactionDateString() {
      let transactionDateString = ''

      if (this.transactionDateTime) {
        transactionDateString = this.transactionDateTime.getDate().toString().padStart(2, '0') + '/' + (this.transactionDateTime.getMonth() + 1).toString().padStart(2, '0') + '/' + this.transactionDateTime.getFullYear()
      }

      return transactionDateString
    },
    transactionTimeString() {
      let transactionTimeString = ''

      if (this.transactionDateTime) {
        transactionTimeString = this.transactionDateTime.getHours().toString().padStart(2, '0') + ":" + this.transactionDateTime.getMinutes().toString().padStart(2, '0') + ":" + this.transactionDateTime.getSeconds().toString().padStart(2, '0')
      }

      return transactionTimeString
    },
    currentDateString() {
      let currentDateString =
        this.currentDate.getDate().toString().padStart(2, "0") +
        "/" +
        (this.currentDate.getMonth() + 1).toString().padStart(2, "0") +
        "/" +
        this.currentDate.getFullYear();

      return currentDateString;
    },
    currentTimeString() {
      let currentTimeString =
        this.currentDate.getHours().toString().padStart(2, "0") +
        ":" +
        this.currentDate.getMinutes().toString().padStart(2, "0") +
        ":" +
        this.currentDate.getSeconds().toString().padStart(2, "0");

      return currentTimeString;
    },
  },
  methods: {
    ...mapActions("supplier_payment", {
      supplierPayment: "supplierPayment",
      unsetCurrentPaymentData: "unsetCurrentPaymentData",
    }),
    getApiURL() {
      return appConfig.apiUrl;
    },
    handlePrintReceiptClick() {
      window.print();
    },
    handleFinishAndCloseClick() {
      this.unsetCurrentPaymentData({
        supplier_id: this.supplierId
      });
      this.$router.push({name: 'Payment'});
    }
  }
};
</script>

<style lang="scss" scoped>
.supplier-payment-confirmation-1013 {
  .loading-code.result-payment-code {
    display: flex;
    justify-content: center;
    span {
      margin: 0 10px;
    }
  }
  .result-payment-code {
    font-weight: bold;
    font-size: 30px;
    margin-top: 15px;
    span {
      display: inline-block;
    }
    .payment-code {
      direction: ltr !important;
    }
  }
  .payment-caption-container {
    align-items: center;
    .payment-caption-wrapper {
      margin-top: 0;
    }
  }
  .supplier-logo {
    max-width: 100px;
    min-width: unset;
  }
  .payment-product-name,
  .payment-confirmation-data-top-block-text,
  .payment-confirmation-data-bottom-block-text {
    font-size: 30px;
  }
  .payment-confirmation-data-block-row,
  .payment-success-data-block-2-columns .payment-success-data-block-row {
    font-size: 20px;
    margin: 5px 0;
  }
  .payment-confirmation-data-block-row.total-price>div {
    font-size: 30px;
    color: #F93959 !important;
    font-weight: bold;
  }
  .payment-confirmation-data {
    max-width: 800px;
    padding: 0;
  }
  .payment-confirmation-data-block-row>div {
    white-space: normal;
  }
  .payment-confirmation-data-block-row-label {
    width: calc(50% - 1px);
    /*min-width: 430px;*/
  }
  .payment-confirmation-data-block-row-data {
    width: calc(50% - 1px);
  }
}
.ltr-type {
  .payment-form {
    text-align: left;
  }
  .input-tooltip-container {
    left: unset;
    right: 0;
  }
  .payment-form-with-tooltips .payment_field_wrapper .input {
    margin-left: 0;
    margin-right: 12px;
  }
  .payment-confirmation-data-block-row>div {
    text-align: left;
  }
}
@media screen and (max-width: 600px) {
  .payment-confirmation-data-block-row-label,
  .payment-confirmation-data-block-row-data,
  .payment-success-data-block-2-columns .payment-success-data-block-row-label,
  .payment-success-data-block-2-columns .payment-success-data-block-row-data {
    display: block;
    width: 100%;
  }
}

.confirm-action {
  align-items: center;
  display: flex;
  border-top: 1px solid #b2bbc2;
  border-bottom: 1px solid #b2bbc2;
  padding: 20px 0;
  margin-top: 30px;
  .print-part {
    align-items: center;
    display: flex;
    img {
      width: 50px;
      margin: 0 10px;
    }

  }
}
.finish-btn {
    padding: 0 20px;
}
</style>

<style>
  @media print {
    header,
    aside,
    button.button.success__print,
    .hide__print,
    nagishli,
    .woot-widget-holder,
    .woot--bubble-holder {
      display: none !important;
    }
    .blank-aside {
      margin: 0 !important;
    }
    html, body {
      height:100%;
      overflow: hidden;
    }
  }
</style>