<template>
  <main
    class="supplier-payment-confirmation supplier-payment-confirmation-1019 page_bg_grey"
  >
    <section class="payment-caption-section">
      <div class="payment-caption-container">
        <div class="payment-caption-wrapper payment-product-name">
          <span>{{ $t("SUPPLIER_PAYMENT.PAYMENT_6CROSSES_NORTH") }}</span>
        </div>

        <div class="payment-caption-wrapper text-center">
          <img
            class="supplier-logo"
            src="/assets/img/logo/6North.png"
          />
        </div>

        <div class="payment-caption-wrapper d-flex justify-content-center pt10">
        </div>
      </div>
    </section>

    <section class="payment-confirmation-data-section">
      <div class="payment-confirmation-data-top-block">
        <span class="payment-confirmation-data-top-block-text color-green">{{$t("SUPPLIER_PAYMENT.PAYMENT6_SUCCESS_TXT")}}</span>
      </div>

      <div class="payment-confirmation-data">
        <div class="payment-confirmation-data-block">
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-title">
              {{ $t("CARMEL_TUNNELS.DETAILS_PLACE_TRANSACTION") }}
            </div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("REGISTER_RESELLER.BUSINESS_NAME") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ businessName }}</div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("SUPPLIER_PAYMENT.BUSINESS_ADDRESS") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ businessAddress }}</div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("COMMON.DATE") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ transactionDateString }}</div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("COMMON.HOUR") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ transactionTimeString }}</div>
          </div>

        </div>

        <div class="payment-confirmation-data-block">
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-title">
              {{ $t("CARMEL_TUNNELS.INVOICE_DETAILS") }}
            </div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("CARMEL_TUNNELS.TRANSACTION_APPROVAL") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ approveNumber }}</div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("REPORT_SALES.SUPPLIER_ID") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ supplierName }}</div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("CARMEL_TUNNELS.NUMBER_CLEARINGHOUSE") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ transactionId }}</div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("SUPPLIER_PAYMENT.INVOICES") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">&nbsp;</div>
          </div>
          <div v-if="paidOptions && paidOptions.length" class="payment-confirmation-data-block-row">
            <div class="payment-option-row" v-for="(option, index) in paidOptions">
              <div class="option-description">
                <label :for="option.id"><span>{{ option.description }}</span> <span class="text-bold">{{ option.car_number }}</span> - <span class="text-bold">{{ option.price + option.currency }}</span></label>
              </div>
            </div>
          </div>
          <div v-else>-----</div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("CARMEL_TUNNELS.SERVICE_FEE") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ finalCommission }}</div>
          </div>

          <div class="payment-confirmation-data-block-row red-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("CARMEL_TUNNELS.PAYMENT_SUM") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ price }}</div>
          </div>

          <div class="payment-confirmation-data-block-row confirm-action">
            <div class="payment-confirmation-data-block-row-label print-part" @click="handlePrintReceiptClick">
              {{ $t("REPORT_SALES.FOR_PRINTING") }}
              <img src="/assets/img/print-light.svg" alt="">
            </div>
            <div class="payment-confirmation-data-block-row-data">
              <button class="primary-btn finish-btn" @click="handleFinishAndCloseClick">
                {{ $t("SUPPLIER_PAYMENT.FINISHED_AND_CLOSED") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="payment-confirmation-data-bottom-block">
        <div class="payment-confirmation-data-bottom-block-text">
          <span class="">{{ $t("CARMEL_TUNNELS.RESULT_TXT") }}</span>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
    import { mapMutations, mapState, mapActions } from "vuex";
    import appConfig from "../../../appConfig";

    export default {
        name: "SupplierPaymentConfirmation1019",
        props: {
            msg: String,
        },
        data() {
            return {
                currentDate: new Date(),
            };
        },
        mounted() {},
        computed: {
            ...mapState({
                current_payment_data: (state) =>
                    state.supplier_payment.current_payment_data,
                supplier_items: (state) => state.supplier.supplier_items,
                suppliers: (state) => state.supplier.suppliers,
                user_details: (state) =>
                    state.account.user_details &&
                    state.account.user_details.hasOwnProperty("details")
                        ? state.account.user_details.details
                        : null,
                transactions_receipts: state => state.supplier_payment.transactions_receipts
            }),
            supplierId() {
                return this.$route.params.supplier_id;
            },
            supplierData() {
                let supplierDataFromSuppliersItemsData = null;
                if (this.supplier_items) {
                    for (let supplier_type in this.supplier_items) {
                        for (let suppliers_type_item of this.supplier_items[supplier_type]) {
                            if (suppliers_type_item.id == this.supplierId) {
                                supplierDataFromSuppliersItemsData = { ...suppliers_type_item };
                                break;
                            }
                        }
                    }
                }

                let supplierDataFromSuppliersData = null;
                if (this.suppliers && this.supplierId in this.suppliers) {
                    supplierDataFromSuppliersData = { ...this.suppliers[this.supplierId] };
                }

                let supplierData = supplierDataFromSuppliersData
                    ? supplierDataFromSuppliersData
                    : supplierDataFromSuppliersItemsData;
                return supplierData ? supplierData : null;
            },
            supplierName() {
                let supplierName = '';
                if (this.supplierData && this.supplierData.name) {
                    supplierName = this.supplierData.name;
                }

                return supplierName;
            },
            paymentData() {
                let paymentData =
                    this.current_payment_data &&
                    this.supplierId in this.current_payment_data
                        ? this.current_payment_data[this.supplierId]
                        : null;

                return paymentData;
            },
            transactionId() {
                let transactionId = this.$route.params.transaction_id || null;
                if (transactionId == null && this.current_transaction_id && this.supplierId in this.current_transaction_id) {
                    transactionId = this.current_transaction_id[this.supplierId];
                }

                return transactionId;
            },
            transactionReceiptData() {
                let transactionReceiptData = null;
                if (this.transactions_receipts && this.transactionId in this.transactions_receipts) {
                    transactionReceiptData = {...this.transactions_receipts[this.transactionId]}
                }

                return transactionReceiptData ? transactionReceiptData : null
            },
            transactionReceiptDataDetails() {
                let transactionReceiptDataDetails = this.transactionReceiptData && 'details' in this.transactionReceiptData
                    ? this.transactionReceiptData['details'] : null;

                return transactionReceiptDataDetails;
            },
            approveNumber() {
                let approveNumber = this.transactionReceiptDataDetails && 'approveNumber' in this.transactionReceiptDataDetails
                    ? this.transactionReceiptDataDetails['approveNumber'] : null;

                return approveNumber;
            },
            finalCommission() {
                let finalCommission = 0;

                if (this.price && this.purchased_amount) {
                    finalCommission = Math.round((parseFloat(parseFloat(this.price) - parseFloat(this.purchased_amount)) + Number.EPSILON) * 100) / 100 || 0;
                }

                return finalCommission;
            },
            price() {
                let price = this.transactionReceiptData && 'price' in this.transactionReceiptData
                    ? this.transactionReceiptData['price'] : null;

                return price;
            },
            paidOptions() {
                let paidOptions = [];
                if (this.transactionReceiptDataDetails
                    && 'options' in this.transactionReceiptDataDetails) {
                    paidOptions = this.transactionReceiptDataDetails['options'];
                }

                return paidOptions;
            },
            purchased_amount() {
                let purchased_amount = 0;
                if (this.paidOptions
                    && this.paidOptions.length) {
                    for (let option of this.paidOptions) {
                        purchased_amount += Math.round((parseFloat(option.price) + Number.EPSILON) * 100) / 100 || 0;
                    }
                }

                return purchased_amount;
            },
            phoneNumber() {
                let phoneNumber = this.transactionReceiptData && 'targetPhone' in this.transactionReceiptData
                    ? this.transactionReceiptData['targetPhone'] : null;
                if (phoneNumber == null && this.paymentData && this.paymentData.hasOwnProperty('phoneNumber')) {
                    phoneNumber = this.paymentData['phoneNumber'];
                }
                return phoneNumber;
            },
            businessName() {
                return this.transactionReceiptData && 'business_name' in this.transactionReceiptData
                    ? this.transactionReceiptData['business_name'] : null;
            },
            businessAddress() {
                return this.transactionReceiptData && 'user_address' in this.transactionReceiptData
                    ? this.transactionReceiptData['user_address'] : null;
            },
            transactionDateTimeString() {
                let transactionDateTimeString = null

                if (this.transactionReceiptData && this.transactionReceiptData.hasOwnProperty('transDateTime')
                    && this.transactionReceiptData.transDateTime) {
                    transactionDateTimeString = this.transactionReceiptData.transDateTime
                }

                return transactionDateTimeString
            },
            transactionDateTime() {
                let transactionDateTime = null

                if (this.transactionDateTimeString) {
                    transactionDateTime = new Date(Date.parse(this.transactionDateTimeString))
                }

                return transactionDateTime
            },
            transactionDateString() {
                let transactionDateString = ''

                if (this.transactionDateTime) {
                    transactionDateString = this.transactionDateTime.getDate().toString().padStart(2, '0') + '/' + (this.transactionDateTime.getMonth() + 1).toString().padStart(2, '0') + '/' + this.transactionDateTime.getFullYear()
                }

                return transactionDateString
            },
            transactionTimeString() {
                let transactionTimeString = ''

                if (this.transactionDateTime) {
                    transactionTimeString = this.transactionDateTime.getHours().toString().padStart(2, '0') + ":" + this.transactionDateTime.getMinutes().toString().padStart(2, '0') + ":" + this.transactionDateTime.getSeconds().toString().padStart(2, '0')
                }

                return transactionTimeString
            },
            currentDateString() {
                let currentDateString =
                    this.currentDate.getDate().toString().padStart(2, "0") +
                    "/" +
                    (this.currentDate.getMonth() + 1).toString().padStart(2, "0") +
                    "/" +
                    this.currentDate.getFullYear();

                return currentDateString;
            },
            currentTimeString() {
                let currentTimeString =
                    this.currentDate.getHours().toString().padStart(2, "0") +
                    ":" +
                    this.currentDate.getMinutes().toString().padStart(2, "0") +
                    ":" +
                    this.currentDate.getSeconds().toString().padStart(2, "0");

                return currentTimeString;
            },
        },
        methods: {
            ...mapMutations("supplier_payment", {
                unsetCurrentPaymentData: "unsetCurrentPaymentData"
            }),
            ...mapActions("supplier_payment", {
                supplierPayment: "supplierPayment",
            }),
            getApiURL() {
                return appConfig.apiUrl;
            },
            handlePrintReceiptClick() {
                alert('receipt should be generated and printed.. still developing..')
            },
            handleFinishAndCloseClick() {
                this.unsetCurrentPaymentData({
                    supplier_id: this.supplierId
                });
                this.$router.push({name: 'Payment'});
            }
        }
    };
</script>

<style lang="scss" scoped>
.supplier-payment-confirmation-1019 {
  .payment-caption-container {
    align-items: center;
    .payment-caption-wrapper {
      margin-top: 0;
    }
  }
  .supplier-logo {
    max-width: 100px;
    min-width: unset;
  }
  .payment-product-name,
  .payment-confirmation-data-top-block-text,
  .payment-confirmation-data-bottom-block-text {
    font-size: 30px;
  }
  .payment-confirmation-data-block-row,
  .payment-success-data-block-2-columns .payment-success-data-block-row {
    font-size: 20px;
  }

  .payment-option-row {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    margin: 10px 0;
    line-height: 40px;
    .option-description {
      width: calc(100%);
      background-color: white;
      padding: 0 12px;
    }
  }
}
.ltr-type {
  .payment-form {
    text-align: left;
  }
  .input-tooltip-container {
    left: unset;
    right: 0;
  }
  .payment-form-with-tooltips .payment_field_wrapper .input {
    margin-left: 0;
    margin-right: 12px;
  }
}
@media screen and (max-width: 600px) {
  .payment-confirmation-data-block-row-label,
  .payment-confirmation-data-block-row-data,
  .payment-success-data-block-2-columns .payment-success-data-block-row-label,
  .payment-success-data-block-2-columns .payment-success-data-block-row-data {
    display: block;
    width: 100%;
  }
}

.confirm-action {
  align-items: center;
  display: flex;
  border-top: 1px solid #b2bbc2;
  border-bottom: 1px solid #b2bbc2;
  padding: 20px 0;
  margin-top: 30px;
  .print-part {
    align-items: center;
    display: flex;
    img {
      width: 50px;
      margin: 0 10px;
    }

  }
}
</style>
