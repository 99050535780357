<template>
  <main
    class="supplier-payment-confirmation supplier-payment-confirmation-213 page_bg_grey"
  >
    <section class="payment-caption-section">
      <div class="payment-caption-container">
        <div class="payment-caption-wrapper payment-product-name">
          <span>{{ $t("SUPPLIER_PAYMENT.PAYMENT_6CROSSES_NORTH") }}</span>
        </div>

        <div class="payment-caption-wrapper text-center">
          <img
            class="supplier-logo"
            src="/assets/img/logo/6North.png"
          />
        </div>

        <div class="payment-caption-wrapper d-flex justify-content-center pt10">
        </div>
      </div>
    </section>

    <section class="payment-confirmation-data-section">
      <div class="payment-confirmation-data-top-block">
        <span class="payment-confirmation-data-top-block-text color-green">{{
          $t("SUPPLIER_PAYMENT.PAYMENT6_SUCCESS_TXT")
        }}</span>
      </div>

      <div class="payment-confirmation-data">
        <div class="payment-confirmation-data-block">
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-title">
              {{ $t("CARMEL_TUNNELS.DETAILS_PLACE_TRANSACTION") }}
            </div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("REGISTER_RESELLER.BUSINESS_NAME") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">רמי לוי</div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("COMMON.DATE") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">
              2018/01/25
            </div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("COMMON.HOUR") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">08:23:15</div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("CARMEL_TUNNELS.TRANSACTION_APPROVAL") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">654542</div>
          </div>
        </div>

        <div class="payment-confirmation-data-block">
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-title">
              {{ $t("CARMEL_TUNNELS.INVOICE_DETAILS") }}
            </div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("SUPPLIER_PAYMENT.ID_NUMBER") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">
              045652456
            </div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("SUPPLIER_PAYMENT.INVOICES") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">
              1.5435434; 2.53454345
            </div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("SUPPLIER_PAYMENT.AMOUNT_OF_INVOICES") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">
              405.95ש"ח
            </div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("CARMEL_TUNNELS.SERVICE_FEE") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">
              7 ש"ח לחשבונית
            </div>
          </div>

          <div class="payment-confirmation-data-block-row red-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("CARMEL_TUNNELS.PAYMENT_SUM") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">
              419.95₪
            </div>
          </div>
          <div class="payment-confirmation-data-block-row confirm-action">
            <div class="payment-confirmation-data-block-row-label print-part">
              {{ $t("REPORT_SALES.FOR_PRINTING") }}
              <img src="/assets/img/print-light.svg" alt="">
            </div>
            <div class="payment-confirmation-data-block-row-data">
              <button class="primary-btn finish-btn">
                {{ $t("SUPPLIER_PAYMENT.FINISHED_AND_CLOSED") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="payment-confirmation-data-bottom-block">
        <div class="payment-confirmation-data-bottom-block-text">
          <span class="">{{ $t("CARMEL_TUNNELS.RESULT_TXT") }}</span>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import appConfig from "../../../appConfig";

export default {
  name: "SupplierPaymentConfirmation213",
  props: {
    msg: String,
  },
  data() {
    return {
      currentDate: new Date(),
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      current_payment_data: (state) =>
        state.supplier_payment.current_payment_data,
      supplier_items: (state) => state.supplier.supplier_items,
      suppliers: (state) => state.supplier.suppliers,
      user_details: (state) =>
        state.account.user_details &&
        state.account.user_details.hasOwnProperty("details")
          ? state.account.user_details.details
          : null,
    }),
    supplierId() {
      return this.$route.params.supplier_id;
    },
    supplierData() {
      let supplierDataFromSuppliersItemsData = null;
      if (this.supplier_items) {
        for (let supplier_type in this.supplier_items) {
          for (let suppliers_type_item of this.supplier_items[supplier_type]) {
            if (suppliers_type_item.id == this.supplierId) {
              supplierDataFromSuppliersItemsData = { ...suppliers_type_item };
              break;
            }
          }
        }
      }

      let supplierDataFromSuppliersData = null;
      if (this.suppliers && this.supplierId in this.suppliers) {
        supplierDataFromSuppliersData = { ...this.suppliers[this.supplierId] };
      }

      let supplierData = supplierDataFromSuppliersData
        ? supplierDataFromSuppliersData
        : supplierDataFromSuppliersItemsData;
      return supplierData ? supplierData : null;
    },
    paymentData() {
      let paymentData =
        this.current_payment_data &&
        this.supplierId in this.current_payment_data
          ? this.current_payment_data[this.supplierId]
          : null;

      return paymentData;
    },
    customerId() {
      return this.paymentData && this.paymentData.hasOwnProperty("customerId")
        ? this.paymentData.customerId
        : "";
    },
    billNumber() {
      return this.paymentData && this.paymentData.hasOwnProperty("billNumber")
        ? this.paymentData.billNumber
        : "";
    },
    sumToPay() {
      return this.paymentData && this.paymentData.hasOwnProperty("sumToPay")
        ? this.paymentData.sumToPay
        : "";
    },
    finalCommission() {
      return this.paymentData &&
        this.paymentData.hasOwnProperty("finalCommission")
        ? this.paymentData.finalCommission
        : "";
    },
    price() {
      return this.paymentData && this.paymentData.hasOwnProperty("price")
        ? this.paymentData.price
        : "";
    },
    itemId() {
      return this.paymentData && this.paymentData.hasOwnProperty("itemId")
        ? this.paymentData.itemId
        : "";
    },
    phoneNumber() {
      return this.paymentData && this.paymentData.hasOwnProperty("phoneNumber")
        ? this.paymentData.phoneNumber
        : "";
    },
    businessName() {
      return this.user_details && this.user_details.businessName
        ? this.user_details.businessName
        : "";
    },
    currentDateString() {
      let currentDateString =
        this.currentDate.getDate().toString().padStart(2, "0") +
        "/" +
        (this.currentDate.getMonth() + 1).toString().padStart(2, "0") +
        "/" +
        this.currentDate.getFullYear();

      return currentDateString;
    },
    currentTimeString() {
      let currentTimeString =
        this.currentDate.getHours().toString().padStart(2, "0") +
        ":" +
        this.currentDate.getMinutes().toString().padStart(2, "0") +
        ":" +
        this.currentDate.getSeconds().toString().padStart(2, "0");

      return currentTimeString;
    },
  },
  created() {},
  methods: {
    ...mapActions("supplier_payment", {
      supplierPayment: "supplierPayment",
    }),
    getApiURL() {
      return appConfig.apiUrl;
    },
    handleSupplierPaymentConfirmationSubmit() {
      const supplierId = this.supplierId;
      const itemId = this.itemId;
      const customerId = this.customerId;
      const billNumber = this.billNumber;
      const price = this.price;
      const phoneNumber = this.phoneNumber;

      if (
        supplierId != "" &&
        itemId != "" &&
        customerId != "" &&
        billNumber != "" &&
        phoneNumber != "" &&
        price != ""
      ) {
        this.supplierPayment({
          supplier_id: supplierId,
          payment_data: {
            itemId: itemId,
            customerId: customerId,
            billNumber: billNumber,
            phoneNumber: phoneNumber,
            price: price,
          },
        }).then(
          (result_data) => {},
          (error) => {}
        );
      }
    },
  },
  updated: function () {},
};
</script>

<style lang="scss" scoped>
.supplier-payment-confirmation-213 {
  .payment-caption-container {
    align-items: center;
    .payment-caption-wrapper {
      margin-top: 0;
    }
  }
  .supplier-logo {
    max-width: 100px;
    min-width: unset;
  }
  .payment-product-name,
  .payment-confirmation-data-top-block-text,
  .payment-confirmation-data-bottom-block-text {
    font-size: 30px;
  }
  .payment-confirmation-data-block-row,
  .payment-success-data-block-2-columns .payment-success-data-block-row {
    font-size: 20px;
  }
}
.ltr-type {
  .payment-form {
    text-align: left;
  }
  .input-tooltip-container {
    left: unset;
    right: 0;
  }
  .payment-form-with-tooltips .payment_field_wrapper .input {
    margin-left: 0;
    margin-right: 12px;
  }
}
@media screen and (max-width: 600px) {
  .payment-confirmation-data-block-row-label,
  .payment-confirmation-data-block-row-data,
  .payment-success-data-block-2-columns .payment-success-data-block-row-label,
  .payment-success-data-block-2-columns .payment-success-data-block-row-data {
    display: block;
    width: 100%;
  }
}

.confirm-action {
  align-items: center;
  display: flex;
  border-top: 1px solid #b2bbc2;
  border-bottom: 1px solid #b2bbc2;
  padding: 20px 0;
  margin-top: 30px;
  .print-part {
    align-items: center;
    display: flex;
    img {
      width: 50px;
      margin: 0 10px;
    }

  }
}
</style>
